import { injectGlobal } from 'styled-components'

// import Montserrat300normalWOFF from '../../static/assets/fonts/montserrat-v12-latin-ext_latin-300.woff'
// import Montserrat300normalWOFF2 from '../../static/assets/fonts/montserrat-v12-latin-ext_latin-300.woff2'
// import Montserrat400normalWOFF from '../../static/assets/fonts/montserrat-v12-latin-ext_latin-regular.woff'
// import Montserrat400normalWOFF2 from '../../static/assets/fonts/montserrat-v12-latin-ext_latin-regular.woff2'
// import Montserrat700normalWOFF from '../../static/assets/fonts/montserrat-v12-latin-ext_latin-700.woff'
// import Montserrat700normalWOFF2 from '../../static/assets/fonts/montserrat-v12-latin-ext_latin-700.woff2'
// const injectFonts = `
//   @font-face {
//     font-display: auto;
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-weight: 300;
//     src: local('Montserrat Bold'), local('Montserrat-Bold'), 
//          url(${Montserrat300normalWOFF2}) format('woff2'), url(${Montserrat300normalWOFF}) format('woff');
//   }
//   @font-face {
//     font-display: auto;
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-weight: 400;
//     src: local('Montserrat Bold'), local('Montserrat-Bold'), 
//          url(${Montserrat400normalWOFF2}) format('woff2'), url(${Montserrat400normalWOFF}) format('woff');
//   }
//   @font-face {
//     font-display: auto;
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-weight: 700;
//     src: local('Montserrat Bold'), local('Montserrat-Bold'), 
//          url(${Montserrat700normalWOFF2}) format('woff2'), url(${Montserrat700normalWOFF}) format('woff');
//   }
// `

injectGlobal`
  * { box-sizing: border-box; }
  body { margin: 0; }
`

const theme = {
    colors: {
      'black': '#000',
      'near-black': '#111',
      'dark-gray': '#333',
      'mid-gray': '#555',
      'gray': ' #777',
      'silver': '#999',
      'light-silver': '#aaa',
      'moon-gray': '#ccc',
      'light-gray': '#eee',
      'near-white': '#f8f8f8',
      'white': '#fff',
      'transparent': 'transparent'
    },
    fonts: {
        sans: 'Montserrat, Helvetica, Arial, sans-serif'
    }
}

export default theme
