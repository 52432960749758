import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Provider } from 'rebass'

import { default as luxbar } from './luxbar.css'
import theme from '../themes/default'

export default ({ children, title }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
    }
    render={data => (
      <Provider theme={theme} bg='near-white' color='dark-gray' style={ { minHeight: '100vh' } }>
        <Helmet
          title={title}
          titleTemplate={ '%s - ' + data.site.siteMetadata.title }
          defaultTitle={data.site.siteMetadata.title}
        >
          <html lang="en" />
          { /* generic meta */ }
          <meta name="description" content={data.site.siteMetadata.description} />
          { /* favicon stuff */ }
          <link rel="apple-touch-icon" href="/assets/site/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/assets/site/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/assets/site/favicon-16x16.png" />
          <link rel="mask-icon" href="/assets/site/safari-pinned-tab.svg" color="#5bbad5" />
          <link rel="shortcut icon" href="/assets/site/favicon.ico" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="msapplication-config" content="/assets/site/browserconfig.xml" />
          <meta name="theme-color" content="#ffffff" />
        </ Helmet>
        {children}
      </Provider>
    )}
  />
)
