import React from 'react'
import BannerDark from '../components/banner-dark'

const Header = ({ children, height, image } ) => (
    <BannerDark minHeight={ height || '100vh' } backgroundImage={ `url('${image}')` } >
        { children } 
    </BannerDark>
  )
  
export default Header


