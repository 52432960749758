import React from 'react'

import { Heading, Text } from 'rebass'
import { Bug } from 'styled-icons/fa-solid'

import { Header, NavBar } from '../components'

import Layout from '../layouts/default'

const NotFoundPage = () => (
  <Layout>
    <NavBar />
    <Header image='/assets/img/banners/macbook-desk-1200x900.jpg'>
      <Bug size={100} />
      <Heading is='h1' fontSize={[4, 5, 6]} fontWeight='300' my={3}>Whoops!</Heading>
      <Text my={2} fontSize={[1, 2]}>404: Page was eaten by a bug in our system.</Text>
    </Header>
  </Layout>
)

export default NotFoundPage
