import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    width: 100%;
    z-index: 1000;
    `

class NavBarBase extends React.Component {
    render() {
      return (
        <Container>
            <header id="luxbar" className="luxbar-fixed" style={ {boxShadow: 'none'} }>
                <input type="checkbox" className="luxbar-checkbox" id="luxbar-checkbox"/>
                <div className="luxbar-menu luxbar-menu-right luxbar-menu-dark" style={ {backgroundColor: 'transparent'} }>
                    <ul className="luxbar-navigation">
                        <li className="luxbar-header">
                            <label className="luxbar-hamburger luxbar-hamburger-doublespin" 
                            id="luxbar-hamburger" htmlFor="luxbar-checkbox"> <span></span> </label>
                        </li>
                        <li className="luxbar-item"><a href="/">Home</a></li>
                        <li className="luxbar-item"><a href="/donate">Donate</a></li>
                    </ul>
                </div>
            </header>   
        </Container>
      );
    };
  };

export const NavBar = styled(NavBarBase)`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;
`

export const NavBarBottom = styled(NavBarBase)`
    bottom: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    left: 0;
    position: fixed;
`

export const NavBarFixed = styled(NavBarBase)`
    
    left: 0;
    position: fixed;
    top: 0;
`
