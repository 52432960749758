import styled from 'styled-components'
import { Banner } from 'rebass'

const BannerDark = styled(Banner)`
    &:after {
        background-color: rgba(0, 0, 0, .5);
        content: "";
        display: block;
        height: ${props => props.minHeight || '100%' };
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    };
    > * {
        z-index: 1;
    }
`

export default BannerDark
